const config = {
  siteName: "<Paylinks>",
  footerText: `All rights reserved © ${new Date().getFullYear()} by`,
  enableAnimatedRoute: false,

  //Production
  apiAuth: "https://auth.paylinks.ca",
  apiDashboard: "https://dashboard.paylinks.ca",
  apiUsers: "https://users.paylinks.ca",
  apiTransactionsPath: "https://transactions.paylinks.ca",
  apiVirtualTerminalPath: "https://virtualterminal.paylinks.ca",

  //Staging
  // apiAuth: "https://authstaging.paylinks.ca",
  // apiDashboard: "https://dashboardstaging.paylinks.ca",
  // apiUsers: "https://usersstaging.paylinks.ca",
  // apiTransactionsPath: "https://transactionsstaging.paylinks.ca",
  // apiVirtualTerminalPath: "https://virtualterminalstaging.paylinks.ca",

  //Development
  // apiAuth: "http://localhost:5000",
  // apiDashboard: "http://localhost:7000",
  // apiUsers: "http://localhost:4040",
  // apiTransactionsPath: "http://localhost:6060",
  // apiVirtualTerminalPath: "http://localhost:4000",

  dashboard: "/dashboard",
};

export default config;
